import React, { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from 'components/Header';
import { mobile } from 'styles/breakpoints';

interface PageContainerProps {
  with100vh?: boolean;
}

const Container = styled.div<{ height: number; with100vh?: boolean }>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const PageContainer: FC<PageContainerProps> = props => {
  const height = use100vh();

  return <Container height={height || 0} {...props} />;
};
