export enum ENVs {
  'production' = 'production',
  'development' = 'development',
}

const NAME = 'Effecto';
const ENV =
  process.env.NODE_ENV === 'production' ? ENVs.production : ENVs.development;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-NHVSB53';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '2b16170c-426c-477f-9b76-b1d08c6a8741';
const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://wellness-a-team-effecto-funnel-api-sandbox.cl2.kilo.live/api/';
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || '';
const FIREBASE_REGISTER_URL =
  process.env.REACT_APP_FIREBASE_REGISTER_URL ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/register';
const REACT_APP_FIREBASE_USER_HAS_PAYED_URL =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAYED_URL ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/userHasPaid';
const PATIENT_APP_BASE_URL = process.env.REACT_APP_PATIENT_APP_BASE_URL || '';
const HYROS_TOKEN = process.env.REACT_APP_HYROS_TOKEN || '';
const FIREBASE_MAILING =
  process.env.REACT_APP_FIREBASE_MAILING ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/sendEmail';

const configs = {
  [ENVs.development]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    FIREBASE_MAILING,
  },
  [ENVs.production]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    FIREBASE_MAILING,
  },
};

export const config = configs[ENV];
