import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ProjectConfigResponse } from '@frontend/api-wrapper';

import { ApiBase } from 'apis/api';
import { handleError } from 'utils/error';

import { setPaymentGateway } from './actions';

export const fetchPaymentGateway =
  () => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    try {
      const response = await ApiBase.get<{ data: ProjectConfigResponse }>(
        '/project',
      );
      dispatch(setPaymentGateway(response.data.data.gateway || null));
    } catch (e) {
      handleError(e as Error);
    }
  };
