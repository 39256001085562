import React, { FC } from 'react';
import styled from 'styled-components';

import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import Disclaimer from './Disclaimer';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import theme from 'utils/theme';
import LandingLayout from './LandingLayout';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${mobile} {
    text-align: center;
    padding-bottom: 1rem;
  }
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 2.5rem;
  max-width: 522px;
  @media ${mobile} {
    padding-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  * {
    font-size: inherit;
    font-family: 'Open Sans';
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 4rem;

  @media ${mobile} {
    text-align: center;
    text-align-last: center;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

const CheckmarkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  @media ${mobile} {
    align-items: center;
    margin-bottom: 1.5rem;
  }
`;

const CheckmarkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.3125rem;
`;

const CheckTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.dark80};
  margin-left: 0.3125rem;
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 2rem;

  @media ${mobile} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  @media ${mobile} {
    justify-content: center;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  max-width: 15rem;
  margin-right: 1rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${mobile} {
    font-family: 'Open Sans';
    height: 3rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const Label = styled(Text)<{ descriptionIsMaxWidth?: boolean }>`
  font-weight: 400;

  * {
    font-size: inherit;
    font-family: 'Open Sans';
  }
  font-family: 'Open Sans';
  @media ${mobile} {
    font-size: 0.875rem;
    width: ${({ descriptionIsMaxWidth }) =>
      descriptionIsMaxWidth ? '100%' : '21.438rem'};
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${mobile} {
    flex-basis: calc(50% - 1rem);
    margin-top: 0.5rem;
  }
`;

const MdpiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Mdpi = styled.div`
  width: 19rem;
  margin-top: 2rem;
  align-self: center;
  @media ${mobile} {
  }
`;

const LeftContainer = styled.div`
  flex: 55;
  margin-right: 1rem;
  @media ${mobile} {
    margin-right: 0;
  }
`;
const RightContainer = styled.div`
  flex: 45;
`;
const DynamicImageMobile = styled(DynamicImage)`
  margin-top: 1.5rem;
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isMobile } = useQuery();

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderCheckmarks = (title: string, index: number) => (
    <CheckmarkItem key={index}>
      <CircleTick
        fill={theme.colors.green}
        width={isMobile ? 16 : 24}
        height={isMobile ? 16 : 24}
      />
      <CheckTitle type={isMobile ? 'body' : 'h3400'}>{title}</CheckTitle>
    </CheckmarkItem>
  );

  const getTitleFont = () => {
    if (isMobile) {
      return question?.titleFontMobile ? question.titleFontMobile : 'h1';
    }
    return question?.titleFont ? question.titleFont : 'h13Rem';
  };

  return (
    <LandingLayout question={question}>
      <LeftContainer>
        {isMobile && question.mobileImg ? (
          <DynamicImageMobile
            src={question.mobileImg}
            alt={question.alt}
            maxHeight="300px"
            width="auto"
            alignCenter
            justifyCenter
          />
        ) : null}
        <TitleContainer>
          <Title
            type={getTitleFont()}
            dangerouslySetInnerHTML={{ __html: question?.title || '' }}
          />
        </TitleContainer>
        {!!question?.description && (
          <DescriptionContainer>
            <Label
              type={isMobile ? 'body' : 'h3400'}
              color="dark80"
              dangerouslySetInnerHTML={{
                __html: question?.description || '',
              }}
              descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
            />
          </DescriptionContainer>
        )}
        {!!question?.checkmarkList && (
          <CheckmarkListContainer>
            {question.checkmarkList.map(renderCheckmarks)}
          </CheckmarkListContainer>
        )}
        <ButtonsTitleContainer>
          <Label
            dangerouslySetInnerHTML={{ __html: question?.label }}
            type={isMobile ? 'bodyS500' : 'body500'}
          />
        </ButtonsTitleContainer>
        {renderGenderOptions()}
        {isMobile && !!question?.mdpiImage?.desktopImg && (
          <MdpiContainer>
            <Mdpi>
              <DynamicImage
                src={question.mdpiImage.desktopImg}
                alt={question.mdpiImage.alt}
              />
            </Mdpi>
          </MdpiContainer>
        )}
        {!isMobile && (
          <Disclaimer paragraphs={question?.disclaimerParagraphs ?? []} />
        )}
      </LeftContainer>
      <RightContainer>
        {isMobile ? (
          <Disclaimer paragraphs={question?.disclaimerParagraphs ?? []} />
        ) : (
          <>
            {question.desktopImg ? (
              <DynamicImageStyled
                src={question.desktopImg}
                alt={question.alt}
              />
            ) : null}
            {!!question?.mdpiImage?.desktopImg && (
              <MdpiContainer>
                <Mdpi>
                  <DynamicImage
                    src={question.mdpiImage.desktopImg}
                    alt={question.mdpiImage.alt}
                  />
                </Mdpi>
              </MdpiContainer>
            )}
          </>
        )}
      </RightContainer>
    </LandingLayout>
  );
};

export default Landing;

const DynamicImageStyled = styled(DynamicImage)`
  width: 100%;
  min-width: 552px;
`;
