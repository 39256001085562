import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { OrderSummarySubItem } from 'types/checkout';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface PaymentDialogOrderSummaryProps {
  selectedProduct: Product | null;
  subItems?: OrderSummarySubItem[];
}

const Container = styled.div`
  padding-top: 1rem;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
`;

const OrderSummaryItemContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (!paddingBottom ? 0 : 0.75)}rem;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 0 0 1rem 0;
`;
const BilledPeriod = styled(Text)`
  color: #77788d;
`;

const OrderSummaryItemContainerPrice = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 4px;
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProduct, subItems, ...props }) => {
  const { subscription_config } = useSelector((s: AppState) => s.user);

  const { isMobile } = useQuery();
  if (!selectedProduct) {
    return null;
  }
  const product = getLocalisedProduct(selectedProduct);

  return (
    <Container {...props}>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="bodyM">{selectedProduct?.name}</Text>
        <Text type="bodyM">
          {`${product.currencyId} ${product.regularPrice} `}
        </Text>
      </OrderSummaryItemContainer>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="bodyM">Discount (-{product.savings}%)</Text>
        <Text type="bodyM" color="red80">
          {`-${product.currencyId} ${product?.discount} `}
        </Text>
      </OrderSummaryItemContainer>
      <Divider />
      <OrderSummaryItemContainer>
        <Text type="body700">Total:</Text>
        <Text type="h4700">
          {`${product.currencyId} ${product.finalPrice} `}
        </Text>
      </OrderSummaryItemContainer>
      <OrderSummaryItemContainerPrice>
        <BilledPeriod type={isMobile ? 'bodyS' : 'bodyM'}>
          {/* If months are equal 1 removes last character
              ex. months -> month
              */}
          {`${subscription_config?.billingPeriod.text1}${product?.months}${
            product?.months === 1
              ? (subscription_config?.billingPeriod.text2 || '').slice(0, -1)
              : subscription_config?.billingPeriod.text2 || ''
          }`}
        </BilledPeriod>
      </OrderSummaryItemContainerPrice>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
