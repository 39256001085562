import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
}

export const Seo: React.FC<Props> = ({ title, children }) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta
      id="description"
      name="description"
      content="Effecto is the most comfortable way to get mental health support!"
    />
    {children}
  </Helmet>
);
