import { createActionCreator } from '@reduxify/utils';

import {
  SET_CODE,
  SET_ERROR_MSG,
  SET_GEOLOCATION,
  SET_QUIZ_ANSWERS,
  SET_SELECTED_PLAN,
  SET_SUB_CONFIG,
  SET_UPSELL_PRODUCTS,
  SET_USER,
} from './constants';
import { IUserState } from './types';

export const setGeolocation =
  createActionCreator<IUserState['geolocation']>(SET_GEOLOCATION);
export const setQuizAnswers =
  createActionCreator<IUserState['quiz_answers']>(SET_QUIZ_ANSWERS);
export const setUser = createActionCreator<IUserState['user']>(SET_USER);
export const setCode = createActionCreator<IUserState['code']>(SET_CODE);
export const setErrorMsg =
  createActionCreator<IUserState['error_msg']>(SET_ERROR_MSG);
export const setSelectedPlan =
  createActionCreator<IUserState['selected_plan']>(SET_SELECTED_PLAN);
export const setUpsellProducts =
  createActionCreator<IUserState['upsell_products']>(SET_UPSELL_PRODUCTS);
export const setSubscriptionConfig =
  createActionCreator<IUserState['subscription_config']>(SET_SUB_CONFIG);
