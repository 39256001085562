import React, { HTMLProps, useMemo } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';
import type { Color, Typography } from '../utils/theme';

interface TextProps extends HTMLProps<HTMLElement> {
  type?: Typography;
  color?: Color;
  fontWeight?: string;
  fontSize?: number;
}

type Partial<Key extends keyof any, Type> = {
  [key in Key]?: Type;
};

type As = Partial<Typography, any>;

const TextStyled = styled.div<{
  color: Color;
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
  letterSpacing?: number;
}>`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : 0}rem;
  color: ${({ color }) => theme.colors[color]};

  * {
    font-family: inherit;
    font-size: inherit;
  }
`;

const Text: FC<TextProps> = ({
  type = 'body',
  color = 'dark100',
  ...props
}) => {
  const textProps = useMemo(
    () => ({ ...theme.typography[type], color }),
    [type, color],
  );

  const as: As = useMemo(
    () => ({
      h1: 'h1',
      h1700: 'h1',
      h13Rem: 'h1',
      count: 'h1',
      countS: 'h1',
      h2: 'h2',
      h2400: 'h2',
      h2600: 'h2',
      h3: 'h3',
      h3500: 'h3',
      h4: 'h4',
      h4400: 'h4',
      body: 'p',
      body500: 'p',
      body600: 'p',
      bodyM: 'p',
      bodyM600: 'p',
      bodyS: 'p',
      bodyS500: 'p',
      caption: 'p',
      captionM: 'p',
      captionS: 'p',
      tab: 'p',
      tabActive: 'p',
      tabNavigation: 'p',
      tabNavigationActive: 'p',
      button: 'span',
      buttonText: 'span',
      buttonTextM: 'span',
    }),
    [type],
  );

  return <TextStyled as={as[type]} {...textProps} {...props} />;
};

export default Text;
