import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Loader, RadioInput, Text } from 'components';
import { PAYMENT_METHOD } from './KiloPayments';
import PaymentMethodsIcon from 'assets/icons/payments/payment-methods.svg';
import PaypalIcon from 'assets/icons/payments/paypal-icon.svg';
import PaypalButtonIcon from 'assets/icons/payments/paypyal-button.svg';
import { mobile } from 'styles/breakpoints';

interface PaymentsProps {
  ids: string[];
  selectedId: string;
  onItemClick: (id: string) => void;
}

interface PaymentProps {
  title?: string;
  id: string;
  selectedId: string;
  topBorder?: boolean;
  onItemClick: (id: string) => void;
  Icon: string;
  InnerComponent?: ReactNode;
}

interface PaymentContainerProps {
  topBorder?: boolean;
}

interface KiloPaymentContainerProps {
  selected: boolean;
  isPaypal: boolean;
}

interface PaymentInnerComponentContainerProps {
  checked: boolean;
}

const PaymentsContainer = styled.div`
  margin-top: 0.375rem;

  #kilo-payments-error {
    .kp-error-message {
      margin-bottom: 0.375rem;
    }
  }
`;

const PaymentItemsContainer = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #f9f9f9;
`;

const PaymentContainer = styled.div<PaymentContainerProps>`
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ topBorder }) => topBorder && `border-top: 1px solid #E4E4EB;`}
`;

const PaypalIconStyled = styled(PaypalIcon)`
  margin-left: 0.75rem;
`;

const StyledPaypalButtonIcon = styled(PaypalButtonIcon)`
  width: 4.3336rem;
  height: 1.375rem;
`;

const KiloPaymentContainer = styled.div<KiloPaymentContainerProps>`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  position: relative;
  height: ${({ isPaypal }) => (isPaypal ? ' 4.3542rem' : 'unset')};

  #kilo-payment-stripe {
    .kp-row {
      display: inline;
    }

    label {
      font-family: ${({ theme }) => theme.typography.bodyM600.fontFamily};
      font-size: ${({ theme }) => theme.typography.bodyM600.fontSize};
      font-weight: ${({ theme }) => theme.typography.bodyM600.fontWeight};
      line-height: ${({ theme }) => theme.typography.bodyM600.lineHeight};
      color: ${({ theme }) => theme.colors.dark100};
      display: block;
      margin-bottom: 0.75rem;
    }

    input {
      font-family: ${({ theme }) => theme.typography.bodyM.fontFamily};
      font-size: ${({ theme }) => theme.typography.bodyM.fontSize};
      font-weight: ${({ theme }) => theme.typography.bodyM.fontWeight};
      line-height: ${({ theme }) => theme.typography.bodyM.lineHeight};
      color: ${({ theme }) => theme.colors.dark100};
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.light100};
      height: 2.5rem;
      ::placeholder {
        color: ${({ theme }) => theme.colors.dark40};
      }
    }

    #kp-stripe-card {
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 1.5rem;
      border: 1px solid ${({ theme }) => theme.colors.light100};
    }

    #kp-stripe-submit {
      width: 100%;
      height: 3rem;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
        0px 2px 4px rgba(96, 97, 112, 0.16);
      background-color: ${({ theme }) => theme.colors.green100};
      margin: 0 auto;

      .kp-button--text {
        font-family: ${({ theme }) => theme.typography.body600.fontFamily};
        font-size: ${({ theme }) => theme.typography.body600.fontSize};
        font-weight: ${({ theme }) => theme.typography.body600.fontWeight};
        line-height: ${({ theme }) => theme.typography.body600.lineHeight};
        color: transparent;
        &:before {
          color: ${({ theme }) => theme.colors.light0};
          content: 'Submit Secure Payment';
          text-indent: 0;
          display: block;
          margin-top: 0.25rem;
          line-height: initial;
        }
      }
    }
  }
`;

const PaymentInnerComponentContainer = styled.div<PaymentInnerComponentContainerProps>`
  padding: 0 1rem 1rem 1rem;
  display: ${({ checked }) => (checked ? 'block' : 'none')};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin-bottom: 1rem;
`;

const PaypalLoaderWrapper = styled.div`
  position: absolute;
  top: 32.5%;
  left: 50.1%;
  transform: translate(-50%, -50%);
`;

const PaypalButton = styled.div`
  background: ${({ theme }) => theme.colors.paypalBackground};
  border-radius: 1.4375rem;
  width: 21.5rem;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: not-allowed;

  @media ${mobile} {
    width: 92.3vw;
  }

  @media (max-width: 20.8125rem) {
    width: 89.5vw;
    height: 2.1875rem;
  }

  @media (max-width: 14.625rem) {
    width: 85vw;
    height: 1.5625rem;
  }
`;

const Payment: FC<PaymentProps> = ({
  title,
  id,
  selectedId,
  topBorder,
  onItemClick,
  Icon,
  InnerComponent,
}) => {
  const checked = id === selectedId;

  const handleItemClick = () => {
    onItemClick(id);
  };

  return (
    <>
      <PaymentContainer topBorder={topBorder} onClick={handleItemClick}>
        <RadioInput checked={checked} />
        {/* ? Getting an error when trying to style Text component */}
        {!!title && (
          <Text type="bodyM" style={{ flex: 1, margin: '0 0.75rem' }}>
            {title}
          </Text>
        )}
        <Icon />
      </PaymentContainer>
      {!!InnerComponent && (
        <PaymentInnerComponentContainer checked={checked}>
          <Divider />
          {InnerComponent}
        </PaymentInnerComponentContainer>
      )}
    </>
  );
};

const OldPayments: FC<PaymentsProps> = ({ ids, selectedId, onItemClick }) => {
  const renderPayments = () =>
    ids.map((id, index) => {
      const topBorder = index + 1 === ids.length;
      console.log(id);
      switch (id) {
        case PAYMENT_METHOD.paypal:
          return (
            <Payment
              key={id}
              id={id}
              selectedId={selectedId}
              topBorder={topBorder}
              onItemClick={onItemClick}
              Icon={PaypalIconStyled}
            />
          );
        case PAYMENT_METHOD.stripe:
          return (
            <Payment
              key={id}
              title="Credit Card"
              id={id}
              selectedId={selectedId}
              topBorder={topBorder}
              onItemClick={onItemClick}
              Icon={PaymentMethodsIcon}
              InnerComponent={
                <KiloPaymentContainer
                  selected={selectedId === PAYMENT_METHOD.stripe}
                  isPaypal={selectedId === PAYMENT_METHOD.paypal}
                >
                  <div id={PAYMENT_METHOD.stripe} />
                </KiloPaymentContainer>
              }
            />
          );
        default:
          return null;
      }
    });

  const renderKiloPayments = () => (
    <>
      <KiloPaymentContainer
        selected={selectedId === PAYMENT_METHOD.paypal}
        isPaypal={selectedId === PAYMENT_METHOD.paypal}
      >
        <div id={PAYMENT_METHOD.paypal} />
        <PaypalLoaderWrapper>
          <PaypalButton>
            <StyledPaypalButtonIcon />
          </PaypalButton>
        </PaypalLoaderWrapper>
      </KiloPaymentContainer>
    </>
  );

  return (
    <PaymentsContainer>
      <div key={selectedId} id="kilo-payments-error" />
      <PaymentItemsContainer>{renderPayments()}</PaymentItemsContainer>
      {renderKiloPayments()}
    </PaymentsContainer>
  );
};

export default OldPayments;
