export const SET_QUIZ_ANSWERS = 'user/SET_QUIZ_ANSWERS';
export const SET_USER = 'user/SET_USER';
export const SET_CODE = 'user/SET_CODE';
export const SET_ERROR_MSG = 'user/SET_ERROR_MSG';
export const SET_GEOLOCATION = 'user/SET_GEOLOCATION';
export const SET_SELECTED_PLAN = 'user/SET_SELECTED_PLAN';
export const SET_UPSELL_PRODUCTS = 'user/SET_UPSELL_PRODUCTS';
export const SET_SUB_CONFIG = 'user/SET_SUB_CONFIG';
export const LOCALISED_COUNTRIES = ['CA', 'NZ', 'AU', 'GB', 'MX', 'US'];
export const LOCALISED_EU_CONTRIES = [
  'AT',
  'BE',
  'HR',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
];
